<template>
    <v-card flat>
        <v-card-text class="ma-0 pl-5 pr-5 pt-0 pb-0">
            <v-file-input
                flat
                show-size
                labe="파일 선택"
                accept=".xls,.xlsx"
                @change="selectFile"
                v-model="attachment.file"
                v-if="!attachment.file"
                truncate-length="50"
            ></v-file-input>
            <v-text-field
                v-if="attachment.file"
                v-model="attachment.file.name"
                readonly
            >
                <template v-slot:append>
                    <v-btn icon @click="removeFile">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </v-card-text>
    </v-card>
</template>

<script>

import {mapState} from 'vuex'

export default {
    name: 'tile_excel_input',
    data: () => ({
    }),
    props: [
        'options'
    ],
    computed: {
        ...mapState('addr_book', {
            attachment: state => state.attachment
        }),
    },
    methods: {
        selectFile(file) {
            if (file) {
                if (this.options.size && file.size > this.options.size.value) {
                    this.$store.commit('addr_book/setAttachment', {
                        'errorMessage': this.options.size.message,
                    })
                    return
                }

                if (!file.type.match('application/vnd.ms-excel') && !file.type.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                    this.$store.commit('addr_book/setAttachment', {
                        'errorMessage': '엑셀 형식의 파일만 지원 가능합니다',
                        'errorSubMessage': `업로드 된 파일 형식: ${file.type}`
                    })
                } else {
                    this.$store.commit('addr_book/setAttachment', {
                        'file': file,
                    })
                    this.$emit('selected')
                }
            }
        },
        removeFile() {
            this.$store.commit('addr_book/setAttachment', {})
        }
    }
}
</script>